Vue.component('intro-component', {
    data: function () {
        return {

        }
    },
    mounted: function () {
        let exdays = 365;
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = 'returningvisitor=true;'+expires+';path=/';
    }
});