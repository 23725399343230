Vue.component("projects-slider-component", {
    data: function () {
        return {
        }
    },
    mounted: function () {
        this.initSlider();
    },
    methods: {
        initSlider: function () {
            let slider = document.querySelector('.projects-slider__slides');

            if (slider) {
                this.projectsSlider = new Flickity(slider, {
                    contain: true,
                    pageDots: false,
                    wrapAround: true,
                    prevNextButtons: false,
                    cellAlign: 'left',
                    autoplay: true,
                    bgLazyLoad: 3,
                    on: {
                        ready: function() {
                            slider.classList.add('flickity-loaded')
                        }
                    },
                });
            }
        },

        nextSlide: function() {
            this.projectsSlider.next(true, false);
        },
        prevSlide: function() {
            this.projectsSlider.previous(true, false);
        }
    },
});