Vue.component("header-component", {
    data: function () {
        return {
            scrolled: false,
            headerContrast: false,
            menuExpanded: false
        }
    },
    mounted: function () {
        let that = this;
        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });

        this.watchScrollPosition();
        this.setLogoType();
    },
    methods: {
        watchScrollPosition: function () {
            let that = this;
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            if (scrollTop > 50) {
                that.scrolled = true;
            } else {
                that.scrolled = false;
            }
        },

        setLogoType: function(){
            let that = this;
            let hero = document.querySelector('.hero');
            let template = document.getElementById('template');

            if(!hero && (!template || template.dataset.template !== "home")) {
                that.headerContrast = true
            } else {
                that.headerContrast = false
            }
        }
    },
});