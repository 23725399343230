Vue.component("services-slider-component", {
    data: function () {
        return {
        }
    },
    mounted: function () {
        this.initSlider();
    },
    computed: {
        slides: function() {
            return this.$refs.serviceSliderSlides.querySelectorAll(
                ".service-slider-item"
            );
        }
    },
    methods: {
        initSlider: function () {
            let slider = document.querySelector('.services-slider');

            let $bgLazyLoad = true;

            // enable prev/next buttons at 768px
            if ( window.matchMedia('screen and (min-width: 768px)').matches ) {
                $bgLazyLoad = 4;
            }

            // disable draggable at 1200px
            if ( window.matchMedia('screen and (min-width: 1200px)').matches ) {
                $bgLazyLoad = 4;
            }

            if (slider) {
                this.heroSlider = new Flickity(slider, {
                    pageDots: false,
                    wrapAround: true,
                    prevNextButtons: false,
                    cellAlign: 'left',
                    autoplay: true,
                    bgLazyLoad: $bgLazyLoad,
                });
                for (let i = 0; i < this.slides.length; i++) {
                    this.heroSlider.append( this.slides[i].cloneNode(true) );
                }
                for (let i = 0; i < this.slides.length; i++) {
                    this.heroSlider.append( this.slides[i].cloneNode(true) );
                }
            }
        },

        nextSlide: function() {
            this.heroSlider.next(true, false);
        },
        prevSlide: function() {
            this.heroSlider.previous(true, false);
        }
    },
});