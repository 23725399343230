Vue.component('usps-component', {
    data: function () {
        return {

        }
    },
    mounted: function () {
        this.usps()
    },
    methods: {
        usps: function(){
            let slider = document.querySelector('.usps__list');
            if(slider) {
                var flkty = new Flickity(slider, {
                    // options
                    cellAlign: 'left',
                    contain: true,
                    prevNextButtons: false,
                    pageDots: false,
                    wrapAround: true,
                    watchCSS: true,
                    autoPlay: true
                });
            }
        }
    },
});